import { Container } from '@material-ui/core';
import React from 'react';
import styles from './Description.module.scss';

export default function Description() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <h2 className={styles.title}>お支払い方法</h2>
        <ul className={styles.ul}>
          <li>毎月1日〜5日にメールやSMSでご請求金額をお知らせ。</li>
          <li>
            毎月27日までに、コンビニ払い・銀行振込でお支払いください。
            <br />
            口座振替の場合は、毎月27日に自動で引き落とされます。
          </li>
        </ul>
      </Container>
    </section>
  );
}
